import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/AuthContext';
import ClockInOut from './components/ClockInOut';
import BreakButton from './components/BreakButton';
import HoursList from './components/HoursList';
import LoginPage from './components/LoginPage';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const UserDashboard = ({ hours, setHours, user }) => {
    const [clockedIn, setClockedIn] = useState(false);
    const [onBreak, setOnBreak] = useState(false);
    const [clockInTime, setClockInTime] = useState(null);
    const [breaks, setBreaks] = useState([]);
    const [currentSession, setCurrentSession] = useState(null);
    const [error, setError] = useState('');

    const formatTime = (date) => {
        return date.toLocaleTimeString();
    };

    const handleClockIn = () => {
        const clockInDate = new Date();
        setClockedIn(true);
        setClockInTime(clockInDate);
        setCurrentSession({
            date: clockInDate.toLocaleDateString(),
            clockInTime: formatTime(clockInDate),
            breaks: [],
        });
        setError('');
    };

    const handleClockOut = async () => {
        if (onBreak) {
            setError('You cannot clock out while on a break. Please end your break first.');
            return;
        }

        const clockOutDate = new Date();
        setClockedIn(false);

        const totalBreakTime = breaks.reduce((acc, breakPeriod) => {
            const breakStart = new Date(breakPeriod.start);
            const breakEnd = new Date(breakPeriod.end);
            return acc + (breakEnd - breakStart);
        }, 0);

        const totalTimeMs = clockOutDate - clockInTime - totalBreakTime;
        const hoursWorked = Math.floor(totalTimeMs / (1000 * 60 * 60));
        const minutesWorked = Math.floor((totalTimeMs % (1000 * 60 * 60)) / (1000 * 60));
        const secondsWorked = Math.floor((totalTimeMs % (1000 * 60)) / 1000);

        const updatedSession = {
            ...currentSession,
            clockOutTime: formatTime(clockOutDate),
            hours: hoursWorked,
            minutes: minutesWorked,
            seconds: secondsWorked,
        };

        const response = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/hours`, updatedSession);
        setHours([response.data, ...hours]);
        setBreaks([]);
        setCurrentSession(null);
    };

    const handleStartBreak = () => {
        const breakStartDate = new Date();
        setOnBreak(true);
        setBreaks([...breaks, { start: breakStartDate }]);
        setCurrentSession({
            ...currentSession,
            breaks: [...currentSession.breaks, { start: formatTime(breakStartDate) }],
        });
        setError('');
    };

    const handleEndBreak = () => {
        const breakEndDate = new Date();
        setOnBreak(false);
        const updatedBreaks = [...breaks];
        updatedBreaks[updatedBreaks.length - 1].end = breakEndDate;

        const updatedSessionBreaks = [...currentSession.breaks];
        updatedSessionBreaks[updatedSessionBreaks.length - 1].end = formatTime(breakEndDate);

        setBreaks(updatedBreaks);
        setCurrentSession({
            ...currentSession,
            breaks: updatedSessionBreaks,
        });
    };

    const handleDelete = async (index) => {
        const id = hours[index]._id;
        await axios.delete(`${process.env.NEXT_PUBLIC_BACKEND_URL}/hours/${id}`);
        const updatedHours = hours.filter((_, i) => i !== index);
        setHours(updatedHours);
    };

    return (
        <div className="container">
            <h1 className="header">Vaibhav's Dashboard</h1>
            <div className="card p-3">
                {error && <div className="alert alert-danger">{error}</div>}
                <ClockInOut onClockIn={handleClockIn} onClockOut={handleClockOut} clockedIn={clockedIn} />
                {clockedIn && <BreakButton onStartBreak={handleStartBreak} onEndBreak={handleEndBreak} onBreak={onBreak} />}
                <HoursList hours={hours} onDelete={handleDelete} user={user} />
            </div>
        </div>
    );
};

const ViewerDashboard = ({ hours, user }) => {
    return (
        <div className="container">
            <h1 className="header">Adel's Dashboard</h1>
            <div className="card p-3">
                <HoursList hours={hours} onDelete={() => {}} user={user} />
            </div>
        </div>
    );
};

const PrivateRoute = ({ children }) => {
    const { user } = useAuth();
    return user ? children : <Navigate to="/login" />;
};

const App = () => {
    const [hours, setHours] = useState([]);
    const { user } = useAuth();

    useEffect(() => {
        const fetchHours = async () => {
            const response = await axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/hours`);
            setHours(response.data.reverse());
        };

        fetchHours();
    }, []);

    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        {user?.username === 'vaibhav' ? <UserDashboard hours={hours} setHours={setHours} user={user} /> : user?.username === 'adel' ? <ViewerDashboard hours={hours} user={user} /> : <Navigate to="/login" />}
                    </PrivateRoute>
                }
            />
        </Routes>
    );
};

const RootApp = () => (
    <Router>
        <AuthProvider>
            <App />
        </AuthProvider>
    </Router>
);

export default RootApp;
