import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ClockInOut = ({ onClockIn, onClockOut, clockedIn }) => {
    return (
        <div className="mb-3 text-center">
            {!clockedIn ? (
                <button className="btn btn-primary btn-lg" onClick={onClockIn}>Clock In</button>
            ) : (
                <button className="btn btn-danger btn-lg" onClick={onClockOut}>Clock Out</button>
            )}
        </div>
    );
};

export default ClockInOut;
