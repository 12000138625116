import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const HoursList = ({ hours, onDelete, user }) => {
    return (
        <ul className="list-group">
            {hours.map((entry, index) => (
                <li className="list-group-item d-flex flex-column" key={index}>
                    <div>
                        <strong>Date:</strong> {entry.date}
                    </div>
                    <div>
                        <strong>Clock In:</strong> {entry.clockInTime}
                    </div>
                    <div>
                        <strong>Breaks:</strong> 
                        {entry.breaks.length === 0 ? " None" : (
                            <ul>
                                {entry.breaks.map((breakPeriod, breakIndex) => (
                                    <li key={breakIndex}>
                                        <strong>Start:</strong> {breakPeriod.start}, <strong>End:</strong> {breakPeriod.end}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div>
                        <strong>Clock Out:</strong> {entry.clockOutTime}
                    </div>
                    <div>
                        <strong>Total:</strong> {entry.hours} hours, {entry.minutes} minutes, {entry.seconds} seconds
                    </div>
                    {user?.username === 'vaibhav' && (
                        <button className="btn btn-danger btn-sm mt-2 align-self-end" onClick={() => onDelete(index)}>Delete</button>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default HoursList;
