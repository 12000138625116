import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const LoginPage = () => {
    const { login } = useAuth();
    const navigate = useNavigate();
    const [userID, setUserID] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = (e) => {
        e.preventDefault();
        // Replace with your actual validation logic
        if (userID === 'vaibhav' && password === 'vaibhavpassword') {
            login(userID);
            navigate('/');
        } else if (userID === 'adel' && password === 'adellucky7') {
            login(userID);
            navigate('/');
        } else {
            setError('Invalid ID or password');
        }
    };

    return (
        <div className="container">
            <h2>Login</h2>
            <form onSubmit={handleLogin}>
                <div className="mb-3">
                    <label htmlFor="userID" className="form-label">User ID</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="userID" 
                        value={userID} 
                        onChange={(e) => setUserID(e.target.value)} 
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input 
                        type="password" 
                        className="form-control" 
                        id="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                    />
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                <button type="submit" className="btn btn-primary">Login</button>
            </form>
        </div>
    );
};

export default LoginPage;
