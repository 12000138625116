import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const BreakButton = ({ onStartBreak, onEndBreak, onBreak }) => {
    return (
        <div className="mb-3 text-center">
            {!onBreak ? (
                <button className="btn btn-warning btn-lg" onClick={onStartBreak}>Start Break</button>
            ) : (
                <button className="btn btn-success btn-lg" onClick={onEndBreak}>End Break</button>
            )}
        </div>
    );
};

export default BreakButton;
